<template>
  <div>
    <b-modal id="view-quote-modal" size="lg" centered v-model="show">
      <b-button variant="close-btn" @click="show = false">
        <i class="fas fa-times"></i>
      </b-button>
      <div class="quote-modal">
        <p>
          Dear {{memberName ? memberName : 'Member'}},
          <br />
          {{ quoteDetails.message ? quoteDetails.message : "This is test Quote. Please Check." }}
          <br />
          Here is your personalized quote.
        </p>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr v-if="!plans.length">
                <td class="text-danger" colspan="3" align="center">
                  No Records Found
                </td>
              </tr>
              <tr v-for="(plan, index) in plans" :key="index">
                <td>
                  <div class="plan-img">
                    <img :src="plan.plan_logo" alt="" class="img-fluid" />
                  </div>
                </td>
                <td>
                  <strong>{{ plan.plan_name }}</strong>
                </td>
                <td>${{ plan.price }}</td>
              </tr>
              <tr>
                <td></td>
                <td>One Time Processing Fee</td>
                <td>
                  ${{
                    feeDetails.one_time_processsing
                      ? feeDetails.one_time_processsing
                      : "0"
                  }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>First Month Cost</td>
                <td>${{ feeDetails.first_time_cost }}</td>
              </tr>
              <tr>
                <td></td>
                <td>Recurring Cost</td>
                <td>${{ feeDetails.recurring_month }}</td>
              </tr>
              <tr>
                <td></td>
                <td><strong>Total Price</strong></td>
                <td>
                  <strong>${{ feeDetails.total }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <p>
          Thank you!
          <br />
          {{ agentDetails.name }}
          <br />
          {{ agentDetails.email }}
          <br />
          {{ agentDetails.phone }}
        </p>
        <p>Please contact to your rep for further enquiry.</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ViewQuoteModal",
  props: {
    quoteDetails: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
      variants: ["close-btn"],
    };
  },
  computed: {
    agentDetails() {
      return {
        name: this.quoteDetails.agent_fname + " " + this.quoteDetails.agent_lname,
        email: this.quoteDetails.agent_email,
        phone: this.quoteDetails.agent_phone, 
      };
    },
    memberName() {
      return this.quoteDetails.first_name ;
    },
    plans() {
      return this.quoteDetails && this.quoteDetails.plans
        ? this.quoteDetails.plans
        : [];
    },
    feeDetails() {
      return this.quoteDetails && this.quoteDetails.fee ?this.quoteDetails.fee : {};
    },
  },
};
</script>