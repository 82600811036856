var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "view-quote-modal",
      "size": "lg",
      "centered": ""
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('div', {
    staticClass: "quote-modal"
  }, [_c('p', [_vm._v(" Dear " + _vm._s(_vm.memberName ? _vm.memberName : 'Member') + ", "), _c('br'), _vm._v(" " + _vm._s(_vm.quoteDetails.message ? _vm.quoteDetails.message : "This is test Quote. Please Check.") + " "), _c('br'), _vm._v(" Here is your personalized quote. ")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [!_vm.plans.length ? _c('tr', [_c('td', {
    staticClass: "text-danger",
    attrs: {
      "colspan": "3",
      "align": "center"
    }
  }, [_vm._v(" No Records Found ")])]) : _vm._e(), _vm._l(_vm.plans, function (plan, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('div', {
      staticClass: "plan-img"
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": plan.plan_logo,
        "alt": ""
      }
    })])]), _c('td', [_c('strong', [_vm._v(_vm._s(plan.plan_name))])]), _c('td', [_vm._v("$" + _vm._s(plan.price))])]);
  }), _c('tr', [_c('td'), _c('td', [_vm._v("One Time Processing Fee")]), _c('td', [_vm._v(" $" + _vm._s(_vm.feeDetails.one_time_processsing ? _vm.feeDetails.one_time_processsing : "0") + " ")])]), _c('tr', [_c('td'), _c('td', [_vm._v("First Month Cost")]), _c('td', [_vm._v("$" + _vm._s(_vm.feeDetails.first_time_cost))])]), _c('tr', [_c('td'), _c('td', [_vm._v("Recurring Cost")]), _c('td', [_vm._v("$" + _vm._s(_vm.feeDetails.recurring_month))])]), _c('tr', [_c('td'), _c('td', [_c('strong', [_vm._v("Total Price")])]), _c('td', [_c('strong', [_vm._v("$" + _vm._s(_vm.feeDetails.total))])])])], 2)])]), _c('hr'), _c('p', [_vm._v(" Thank you! "), _c('br'), _vm._v(" " + _vm._s(_vm.agentDetails.name) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.agentDetails.email) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.agentDetails.phone) + " ")]), _c('p', [_vm._v("Please contact to your rep for further enquiry.")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }