var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "resend-quote-modal",
      "size": "md",
      "centered": ""
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": function click($event) {
        _vm.show = false;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('div', {
    staticClass: "quote-modal"
  }, [_c('strong', [_vm._v("Do you want to send the quote?")]), _c('button', {
    staticClass: "btn univ-btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.resendQuote();
      }
    }
  }, [_vm._v(" Resend Quotes ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }