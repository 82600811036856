var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [_c('tr', [!_vm.quoteList.records || _vm.quoteList.records.length == 0 ? _c('td', {
    attrs: {
      "colspan": "5",
      "align": "center"
    }
  }, [_vm._v(" No records found. ")]) : _vm._e()]), _vm._l(_vm.quoteList.records, function (record, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(record.agent_info.rep_full_name))]), _c('br'), _vm._v(" " + _vm._s(record.agent_info.agent_code) + " "), _c('br'), _vm._v(" " + _vm._s(record.agent_info.agent_email) + " ")]), _c('td', [_vm._v(_vm._s(record.member_email) + " "), _c('br'), _c('strong', [_vm._v("Verification Code : ")]), _vm._v(" " + _vm._s(record.code) + " ")]), _c('td', [_c('strong', [_vm._v("Quote Sent Date:")]), _c('br'), _vm._v(" " + _vm._s(record.sent_date) + " "), _c('br'), _c('strong', [_vm._v("Quote Sent Time:")]), _c('br'), _vm._v(" " + _vm._s(record.sent_time) + " ")]), _c('td', [_c('span', {
      staticClass: "text-capitalize",
      class: _vm.getClassName(record.status)
    }, [_vm._v(_vm._s(record.status.toLowerCase()))])]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [!record.new_quote ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": "javascript:void(0);",
        "title": "View Details"
      },
      on: {
        "click": function click($event) {
          return _vm.getQuoteDetails(record.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-info",
      attrs: {
        "aria-hidden": "true"
      }
    })]) : _vm._e(), record.status != 'COMPLETED' ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": "javascript:void(0)",
        "title": "Resend Quotes"
      },
      on: {
        "click": function click($event) {
          return _vm.openResendQuoteModal(record.id, 'resend-quote-modal');
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-paper-plane",
      attrs: {
        "aria-hidden": "true"
      }
    })]) : _vm._e(), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      class: [record.quote_seen ? 'quotes-viewed' : 'quotes-unseen'],
      attrs: {
        "href": "javascript:void(0)",
        "title": record.quote_seen ? 'Quotes Viewed' : 'Quotes Unseen'
      }
    }, [_c('i', {
      staticClass: "far fa-eye"
    })])])])]);
  })], 2)]), _c('view-quote-modal', {
    attrs: {
      "quoteDetails": _vm.quoteDetails
    }
  }), _c('resend-quote-modal', {
    attrs: {
      "rowId": _vm.rowId
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Rep Details")]), _c('th', [_vm._v("Member Email")]), _c('th', [_vm._v("Date")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Actions")])])]);
}]

export { render, staticRenderFns }