<template>
  <div class="quote-card">
    <form @submit.prevent="searchQuotes">
      <div class="form-group row">
        <div class="col-xxl-3 mb-4">
          <label>Rep Name</label>
          <input
            type="text"
            class="viewQuotes-field"
            name=""
            placeholder="Enter Rep Name"
            v-model="filters.agent_name"
          />
        </div>

        <div class="col-xxl-3 mb-4">
          <label>Rep Code</label>
          <input
            type="text"
            class="viewQuotes-field"
            name=""
            placeholder="Enter Rep Code"
            v-model="filters.agent_code"
          />
        </div>
        <div class="col-xxl-3 mb-4">
          <label>Quote Sent Status</label>
          <select class="viewQuotes-field" name="" v-model="filters.status">
            <option value="">Select Status</option>
            <option value="Started">Started</option>
            <option value="Not Started">Not Started</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
        <div class="col-xxl-3 mb-4">
          <div class="enrollment-date">
            <label>Quote Sent Date</label>
            <date-picker
              input-class="viewQuotes-field"
              placeholder="Quote sent date"
              type="date"
              v-model="filters.start_date"
              value-type="format"
               format="MM/DD/YYYY"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="row justify-content-md-end">
        <div class="col-xxl-3 col-xl-3 text-end">
          <button type="submit"
                  @click.prevent='renderPersonalizedModal'
                  class="new-quote-btn">New Individual Quote</button>
        </div>
        <div class="col-xxl-2 col-xl-3 text-end">
          <button type="submit" class="univ-btn">Search</button>
        </div>
      </div>
    </form>
    <div>
      <personalized-quote-submit @renderViewList="searchQuotes"/>
    </div>
  </div>
</template>
<script>
import PersonalizedQuoteSubmit from "../modal/PersonalizedQuoteSubmit";
export default {
  name: "QuoteFilter",
  components: {
    PersonalizedQuoteSubmit
  },
  props: {
    filters: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    searchQuotes() {
      this.$emit("onSearch", this.filters);
    },
    renderPersonalizedModal () {
       let app = this
       app.$bvModal.show('personalized-quote-modal')
    }
  },
};
</script>