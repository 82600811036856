<template>
  <div class="page-wrapper viewQuotes-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Personalized Quoting System (PQS)</h1>
        <button class="clear-search" @click="clearSearch">Clear Search</button>
      </div>
      <quote-filter :filters="quoteFilters" @onSearch="getFilteredRecords" />
      <div class="quote-card">
        <div
          class="d-flex justify-content-between align-items-center top-pagination"
        >
          <page-result :rows.sync="quoteList" />
          <pagination :rows.sync="quoteList" :filters="quoteFilters" />
        </div>
        <quote-list :quoteList.sync="quoteList" />
        <div
          class="d-flex justify-content-between align-items-center"
        >
          <page-result :rows.sync="quoteList" />
          <pagination :rows.sync="quoteList" :filters="quoteFilters" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuoteFilter from "../ViewQuotes/partials/QuoteFilter.vue";
import QuoteList from "../ViewQuotes/partials/QuoteList.vue";
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";

const filters = {
  agent_name: "",
  agent_code: "",
  status: "",
  start_date: "",
};
export default {
  name: "ViewQuotes",
  components: {
    QuoteFilter,
    QuoteList,
  },
  data() {
    return {
      quoteFilters: { ...filters },
      quoteList: [],
      breadcrumbItems: [{ title: "PQS", active: true }],
    };
  },
  methods: {
    async getQuoteList(filters = {}) {
      let url = "v2/quote/list";
      if (Object.keys(filters).length) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `${url}?${filterUrl}`;
      }
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          this.quoteList = res.data;
        })
        .catch(() => {
          this.quoteList = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
    getFilteredRecords(filters) {
      this.getQuoteList(filters);
    },
    clearSearch() {
      this.quoteFilters = { ...filters };
      this.getQuoteList(this.quoteFilters);
    },
  },
  created() {
    this.getQuoteList();
  },
};
</script>
<style src="../../assets/css/view-quotes.css"></style>
<style scoped>
.quote-card .top-pagination {
  margin-bottom: 20px;
}
</style>
