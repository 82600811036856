<template>
  <div>
    <b-modal id="resend-quote-modal" size="md" centered v-model="show">
      <b-button variant="close-btn" @click="show = false">
        <i class="fas fa-times"></i>
      </b-button>
      <div class="quote-modal">
        <strong>Do you want to send the quote?</strong>
        <button
          type="button"
          class="btn univ-btn btn-secondary"
          @click="resendQuote()"
        >
          Resend Quotes
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "ResendQuoteModal",
  props: {
    rowId: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    async resendQuote() {
      let url = `v2/quote/resend-email?id=${this.rowId}`;
      let loader = this.$loading.show();
      await Api.post(url)
        .then((res) => {
          this.$notify(res.data.message, "success");
        })
        .catch(() => this.$notify("Error", "error"))
        .finally(() => {
          loader.hide();
          this.$bvModal.hide('resend-quote-modal')
        });
    },
  },
};
</script>
<style scoped>
.quote-modal .univ-btn {
  margin-top: 20px;
}
</style>