var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper viewQuotes-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Personalized Quoting System (PQS)")]), _c('button', {
    staticClass: "clear-search",
    on: {
      "click": _vm.clearSearch
    }
  }, [_vm._v("Clear Search")])]), _c('quote-filter', {
    attrs: {
      "filters": _vm.quoteFilters
    },
    on: {
      "onSearch": _vm.getFilteredRecords
    }
  }), _c('div', {
    staticClass: "quote-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center top-pagination"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.quoteList
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.quoteList = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.quoteList,
      "filters": _vm.quoteFilters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.quoteList = $event;
      }
    }
  })], 1), _c('quote-list', {
    attrs: {
      "quoteList": _vm.quoteList
    },
    on: {
      "update:quoteList": function updateQuoteList($event) {
        _vm.quoteList = $event;
      },
      "update:quote-list": function updateQuoteList($event) {
        _vm.quoteList = $event;
      }
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.quoteList
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.quoteList = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.quoteList,
      "filters": _vm.quoteFilters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.quoteList = $event;
      }
    }
  })], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }