<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Rep Details</th>
          <th>Member Email</th>
          <th>Date</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-if="!quoteList.records || quoteList.records.length == 0"
            colspan="5"
            align="center"
          >
            No records found.
          </td>
        </tr>
        <tr v-for="(record, index) in quoteList.records" :key="index">
          <td>
            <strong>{{ record.agent_info.rep_full_name }}</strong>
            <br />
            {{ record.agent_info.agent_code }}
            <br />
            {{ record.agent_info.agent_email }}
          </td>
          <td>{{ record.member_email }}
            <br />
              <strong>Verification Code : </strong> {{record.code}}
          </td>
          <td>
            <strong>Quote Sent Date:</strong>
            <br />
            {{ record.sent_date }}
            <br />
            <strong>Quote Sent Time:</strong>
            <br />
            {{ record.sent_time }}
          </td>
          <td>
            <span
              class="text-capitalize"
              :class="getClassName(record.status)"
              >{{ record.status.toLowerCase() }}</span
            >
          </td>
          <td>
            <div class="action-icons">
              <a
                href="javascript:void(0);"
                v-b-tooltip.hover
                v-if="!(record.new_quote)"
                title="View Details"
                @click="getQuoteDetails(record.id)"
              >
                <i class="fas fa-info" aria-hidden="true"></i>
              </a>
              <a v-if="record.status != 'COMPLETED'"
                href="javascript:void(0)"
                v-b-tooltip.hover
                title="Resend Quotes"
                @click="openResendQuoteModal(record.id, 'resend-quote-modal')"
              >
                <i class="fas fa-paper-plane" aria-hidden="true"></i>
              </a>
              <a
                href="javascript:void(0)"
                :class="[record.quote_seen ? 'quotes-viewed' : 'quotes-unseen']"
                v-b-tooltip.hover
                :title="record.quote_seen ? 'Quotes Viewed' : 'Quotes Unseen'"
              >
                <i class="far fa-eye"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <view-quote-modal :quoteDetails="quoteDetails" />
    <resend-quote-modal :rowId="rowId" />
  </div>
</template>
<script>
import ViewQuoteModal from "../modal/ViewQuoteModal.vue";
import ResendQuoteModal from "../modal/ResendQuoteModal.vue";
import Api from "@/resource/Api";
export default {
  name: "QuoteList",
  components: {
    ViewQuoteModal,
    ResendQuoteModal,
  },
  props: {
    quoteList: {
      required: false,
      default: [],
    },
  },
  data() {
    return {
      quoteDetails: "",
      rowId: "",
    };
  },

  methods: {
    getClassName(status) {
      if (status == "NOT STARTED") {
        return "not-started";
      }
      return status.toLowerCase();
    },
    async getQuoteDetails(id) {
      let url = `v2/quote/details?id=${id}`;
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          this.quoteDetails = res.data.data;
          this.$bvModal.show("view-quote-modal");
        })
        .catch(() => {
          this.quoteDetails = "";
          this.$notify("Error! Unable To Load Details", "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    openResendQuoteModal(id, modalId) {
      this.rowId = id;
      this.$bvModal.show(modalId);
    },
  },
};
</script>

<style scoped>
.quotes-unseen {
  background: #ea584e;
}
</style>
