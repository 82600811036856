<template>
  <div>
    <b-modal id="personalized-quote-modal"
             @show="getPlatformList"
             size="lg" centered>
      <b-button variant="close-btn" @click="closePersonalizedModal">
        <i class="fas fa-times"></i>
      </b-button>
      <div class="quote-modal text-center">
        <h5><strong>Send New Individual Personalized Quote</strong></h5>
      </div>
      <div class="personalized-quote-container send-quote-wrap">
        <div class="row mt-2">
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="text-left">First Name</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="firstName"
                class="input-field"
                :class="{'form-invalid': $v.firstName.$error}"
                placeholder="Enter First Name"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.firstName.required && $v.firstName.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title text-left">Last Name</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="lastName"
                class="input-field"
                :class="{'form-invalid': $v.lastName.$error}"
                placeholder="Enter Last Name"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.lastName.required && $v.lastName.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title mb-2">DOB</label>
            <span class="required-asterisk">*</span>
            <date-picker
                aria-describedby="input-feedback"
                placeholder="MM/DD/YYYY"
                @input="$v.dob.$touch();"
                class="send-quote-date-picker"
                v-model="dob"
                :class="{'date-invalid': $v.dob.$error}"
                value-type="format"
                format="MM/DD/YYYY"
            >
            </date-picker>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
              Field is required
            </div>
            <div class="form-error ml-3 mt-2"  v-if="$v.dob.required && $v.dob.$error">
              You must be 18 years to proceed
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title">Zip</label>
            <span class="required-asterisk">*</span>
            <input
                type="number"
                :class="{'form-invalid': $v.zip.$error}"
                @input="$v.zip.$touch();"
                v-model="zip"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="5"
                class="input-field"
                placeholder="Enter Zip"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
              Field is required
            </div>
            <div class="form-error text-left ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
              Enter 5 digit zip code.
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title">Tier</label>
            <span class="required-asterisk">*</span>
            <select v-model="tier"
                    :class="{'form-invalid': $v.tier.$error}"
                    class="send-custom-select  mt-1">
              <option value="" disabled>Choose Tier</option>
              <option  v-for="(tier, index) in tierOptions"
                       :value="tier.value"
                       :key="index">{{tier.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.tier.required && $v.tier.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="text-left">Salary <span class="optional-text">(Optional)</span></label>
            <input
                type="number"
                v-model="salary"
                class="input-field"
                placeholder="Enter Salary"
            />
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title">Gender</label>
            <span class="required-asterisk">*</span>
            <select v-model="gender"
                    :class="{'form-invalid': $v.gender.$error}"
                    class="send-custom-select  mt-1">
              <option value="" disabled>Choose Gender</option>
              <option  v-for="(gender, index) in genderOptions"
                       :value="gender.value"
                       :key="index">{{gender.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.gender.required && $v.gender.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title">Email</label>
            <span class="required-asterisk">*</span>
            <input
                type="text"
                v-model="email"
                @input="$v.email.$touch();"
                class="input-field"
                :class="{'form-invalid': $v.email.$error}"
                placeholder="Enter Email"
            />
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.email.required && $v.email.$error">
              Field is required
            </div>
            <div class="form-error  text-left ml-3 mt-2" v-if="$v.email.required && $v.email.$invalid">
              Enter valid email address
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label class="label-title">Enrollment Platform</label>
            <span class="required-asterisk">*</span>
            <select v-model="platform"
                    @change="getPlatformInfo()"
                    :class="{'form-invalid': $v.platform.$error}"
                    class="send-custom-select  mt-1">
              <option :value=null disabled>Choose Enrollment Platform</option>
              <option  v-for="(platform, index) in platformList"
                       :value="platform"
                       :key="index">{{platform.text}}</option>
            </select>
            <div class="form-error text-left ml-3 mt-2"  v-if="!$v.platform.required && $v.platform.$error">
              Field is required
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3" v-if="renderPlanCategory">
            <label class="label-title">Plan Category</label>
            <multiselect
                class="custom-multiselect"
                style="width: 100% !important; margin-top: 6px !important; margin-left: 0 !important;"
                v-model="selectedCategoryOptions"
                placeholder="Select Plan Category"
                :options="categoryList"
                label="text"
                :searchable="true"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @input="onSelectCategory"
            />
          </div>
          <div class="col-xxl-8 col-lg-8 col-md-8 mb-3" v-if="renderPlanList">
            <label class="label-title">Plans</label>
            <multiselect
                style="width: 100% !important; margin-top: 6px !important; margin-left: 0 !important;"
                v-model="selectedPlanOptions"
                placeholder="Select plan"
                :options="planList"
                label="text"
                :searchable="true"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @input="onSelectPlan"
            />
          </div>
        </div>
        <div class="row justify-content-center mb-3">
          <div class="col-xxl-2 col-xl-3">
            <button type="submit" @click="submitQuote"
                    class="new-quote-btn">Submit</button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {required, email} from "vuelidate/lib/validators";
import {checkValidZip, isOver18} from "../../../utils/validator";
import Api from "@/resource/Api";
export default {
  name: "PersonalizedQuoteSubmission",
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    dob: {
      required,
      isOver18
    },
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    gender: {
      required
    },
    email: {
      required,
      email
    },
    platform: {
      required
    }
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      dob: '',
      zip: '',
      tier: '',
      salary: '',
      platform: '',
      platformName: '',
      email: '',
      gender: '',
      selectedPlanOptions: [],
      selectedPlanId: [],
      selectedCategoryOptions: [],
      selectedCategory: [],
      tierOptions: [
        { value: 'IO', text: 'Member Only'},
        { value: 'IS', text: 'Member + Spouse'},
        { value: 'IC', text: 'Member + Children'},
        { value: 'IF', text: 'Family'}
      ],
      genderOptions: [
        { value: 'M', text: 'Male' },
        { value: 'F', text: 'Female'}
      ],
      platformList: [],
      categoryList: [],
      planList: [],
      renderPlanCategory: false,
      renderPlanList: false
    }
  },
  methods: {
    closePersonalizedModal () {
      let app = this
      app.$bvModal.hide('personalized-quote-modal')
    },
    onSelectPlan(selected) {
      this.selectedPlanId = selected.map(option => option.value);
    },
    onSelectCategory(selected) {
      this.selectedCategory = selected.map(option => option.value);
      this.getPlanList()
    },
    clearSendQuoteInfo () {
      let app = this
      app.firstName = ''
      app.lastName = ''
      app.dob = ''
      app.tier = ''
      app.zip = ''
      app.salary = ''
      app.gender = ''
      app.email = ''
      app.portal = ''
      app.platform = null
      app.selectedPlanOptions = []
      app.selectedPlanId = []
      app.selectedCategoryOptions = [],
      app.selectedCategory = [],
      app.renderPlanCategory = false
      app.renderPlanList = false
      app.$v.$reset();
    },
    getPlatformInfo () {
      let app = this
      app.platformName = app.platform.text
      app.getCategoryList();
    },
    getPlatformList () {
      let app = this
      app.clearSendQuoteInfo();
      const url = `v2/quote/platform`
      let loader = app.$loading.show();
      Api.get(url)
          .then((response) => {
            app.platformList = []
            let plaformListsResponse = response.data.data
            plaformListsResponse.forEach ((platform) => {
              let Obj = {
                text: platform.platform_website,
                value: platform.gid
              }
              app.platformList.push(Obj)
            })
            loader.hide();
          }).finally( () => {
        loader.hide();
      })
    },
    getCategoryList () {
      let app = this
      app.$v.$reset();
      app.renderPlanCategory = false
      let query = {}
      query['member_fname'] = app.firstName
      query['member_lname'] = app.lastName
      query['portal_id'] = app.platform.value
      query['dob'] = app.dob
      query['tier'] = app.tier
      query['zip_code'] = app.zip
      query['salary'] = app.salary
      query['gender'] = app.gender
      query['email'] = app.email

      const queryString = new URLSearchParams(query).toString();
      const url = `v2/quote/get-plan-category?${queryString}`;
      let loader = app.$loading.show();
      Api.get(url)
          .then((response) => {
            app.categoryList = []
            let categoryListsResponse = response.data.data
            categoryListsResponse.forEach ((category) => {
              let Obj = {
                text: category.charAt(0).toUpperCase() + category.slice(1).toLowerCase(),
                value: category
              }
              app.categoryList.push(Obj)
            })
            app.renderPlanCategory = true
            app.renderPlanList = false
            app.selectedCategoryOptions = [],
            app.selectedCategory = [],
            app.selectedPlanOptions = []
            app.selectedPlanId = []
          })
          .catch((err) => {
            app.renderPlanCategory = false
            app.renderPlanList = false
            app.platform = null
            app.selectedCategoryOptions = [],
            app.selectedCategory = [],
            app.selectedPlanOptions = []
            app.selectedPlanId = []
            app.$notify(err.response.data.message, "error");
          })
          .finally( () => {
            loader.hide();
          })
    },
    getPlanList () {
      let app = this
      let query = {}
      query['member_fname'] = app.firstName
      query['member_lname'] = app.lastName
      query['dob'] = app.dob
      query['tier'] = app.tier
      query['zip_code'] = app.zip
      query['salary'] = app.salary,
      query['portal_id'] = app.platform.value
      query['gender'] = app.gender,
      query['email'] = app.email

      const queryString = new URLSearchParams(query).toString();
      const categoryParams = app.selectedCategory.map(cat => `category[]=${encodeURIComponent(cat)}`).join('&');
      const url = `v2/quote/get-plan-list?${queryString}&${categoryParams}`;
      let loader = app.$loading.show();
      Api.get(url)
          .then((response) => {
            app.planList = []
            let planListsResponse = response.data.data
            planListsResponse.forEach ((plan) => {
              let Obj = {
                text: `${plan.plan_name} - $${plan.plan_price}`,
                value: plan.plan_id_new
              }
              app.planList.push(Obj)
            })
            loader.hide();
            app.renderPlanList = true
            app.renderPlanCategory = true
            app.selectedPlanOptions = []
            app.selectedPlanId = []
          })
          .catch((err) => {
            app.renderPlanCategory = true
            app.selectedCategoryOptions = [],
            app.selectedCategory = [],
            app.renderPlanList = false
            app.selectedPlanOptions = []
            app.selectedPlanId = []
            app.$notify(err.response.data.message, "error");
          })
          .finally( () => {
            loader.hide();
          })
    },
    submitQuote () {
      let app = this
      app.$v.$touch();
      if (app.$v.$invalid) {
        return false;
      } else {
        let url;
        let payload = {}
        url = `v2/quote/store`
        payload['member_fname'] = app.firstName
        payload['member_lname'] = app.lastName
        payload['dob'] = app.dob
        payload['tier'] = app.tier
        payload['zip_code'] = app.zip
        payload['salary'] = app.salary,
        payload['gender'] = app.gender,
        payload['email'] = app.email,
        payload['portal'] = app.platform.text
        payload['portal_id'] = app.platform.value
        payload['category'] = app.selectedCategory
        payload['plans'] = app.selectedPlanId
        let loader = this.$loading.show();
        Api.post(url, payload)
            .then((res) => {
              let filter = {}
              app.$notify(res.data.message);
              app.$bvModal.hide('personalized-quote-modal')
              app.$emit("renderViewList", filter);
            })
            .catch((err) => {
              app.$notify(err.response.data.message, "error");
            })
            .finally(() => {
              loader.hide();
            });
        app.$v.$reset();
      }

    }
  }
}
</script>

<style scoped>

</style>